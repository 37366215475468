<template>
  <div>
    <!-- INSERIMENTO DATI PAGAMENTO -->
    <div
      class="columns is-mobile is-desktop is-centered"
      v-if="!receiverAddress && !showPin"
    >
      <div class="is-12 is-9-desktop">
        <div style="padding: 10px 0 0 0">
          Inserisci i dati dell'ordine FICOS:
        </div>
        <hr />
        Scrivi qui il numero d'ordine:<br />
        <b-input
          type="text"
          style="margin-bottom: 20px"
          class="amount-input"
          v-model="order"
        />
        Scrivi qui il totale in Euro:<br />
        <b-input
          type="number"
          style="margin-bottom: 20px"
          class="amount-input"
          v-model="amount"
        />
        <b-button
          v-if="!isCheckingBalance"
          type="is-primary"
          v-on:click="checkAmountAndProceed()"
          size="is-medium"
          >PROCEDI</b-button
        >
        <div v-if="isCheckingBalance">
          Controllo se la tessera ha abbastanza fondi...
        </div>
      </div>
    </div>
    <!-- INSERIMENTO AMMONTARE -->

    <!-- INSERIMENTO PIN -->
    <div
      class="fullscreen"
      style="background-image: none; padding: 20px"
      v-if="showPin"
    >
      <b-button
        v-on:click="showPin = false"
        type="is-primary"
        style="
          width: 50px;
          position: fixed;
          z-index: 999;
          top: 10px;
          right: 10px;
        "
        >X</b-button
      >
      <img
        style="margin-bottom: -10px; margin-top: 00px"
        src="../assets/pos.gif"
        width="200"
      /><br />
      {{ $t("insertPinOne") }} <b>{{ $t("insertPinTwo") }}</b>
      {{ $t("insertPinThree") }}

      <div class="display-number" style="padding: 25px 0">
        <span
          style="font-size: 30px; line-height: 40px; padding: 10px 0"
          v-for="(digit, index) in pindigits"
          v-bind:key="index"
          >*</span
        >
      </div>
      <div style="text-align: center">
        <div v-on:click="addPin(1)" class="pos-btn">1</div>
        <div v-on:click="addPin(2)" class="pos-btn">2</div>
        <div v-on:click="addPin(3)" class="pos-btn">3</div>
        <div v-on:click="addPin(4)" class="pos-btn">4</div>
        <div v-on:click="addPin(5)" class="pos-btn">5</div>
        <div v-on:click="addPin(6)" class="pos-btn">6</div>
        <div v-on:click="addPin(7)" class="pos-btn">7</div>
        <div v-on:click="addPin(8)" class="pos-btn">8</div>
        <div v-on:click="addPin(9)" class="pos-btn">9</div>
        <div v-on:click="addPin(0)" class="pos-btn">0</div>
        <div v-on:click="removePin()" class="pos-btn">C</div>
      </div>
      <div v-if="!isPaying">
        <b-button
          type="is-primary"
          v-on:click="unlockAndSend"
          style="margin-top: 15px"
          size="is-medium"
          >{{ $t("send").toUpperCase() }}</b-button
        >
      </div>
      <div v-if="isPaying">{{ $t("loadingSend") }}</div>
    </div>
    <!-- INSERIMENTO PIN -->

    <!-- PAGAMENTO EFFETTUATO -->
    <div class="fullscreen" v-if="showSuccess" style="background-image: none">
      <img src="../assets/paymentdone.gif" width="100%" /><br />
      <div style="padding: 20px">
        <h2 class="title is-3">{{ $t("congrats") }}</h2>
        <br />
        {{ $t("okTransaction") }}<br /><br /><br />
        <a href="/#/">
          <b-button type="is-primary" size="is-medium">{{
            $t("fallBack")
          }}</b-button>
        </a>
      </div>
    </div>
    <!-- PAGAMENTO EFFETTUATO -->
  </div>
</template>

<script>
const EthDiD = require("eth-did-core");
const axios = require("axios");
import { Device } from "@capacitor/device";
const ABI_TOKEN = require("../abi/abi_token.json");

export default {
  data() {
    return {
      did: new EthDiD(true),
      device: {
        platform: "web",
      },
      address: "",
      hash: "",
      wallet: "",
      axios: axios,
      isLoading: true,
      mnemonic: "",
      order: "",
      showScan: false,
      isChecking: false,
      amount: "",
      showWaiting: false,
      showSendScan: false,
      showReceiveScan: false,
      showPin: false,
      showDigitalCard: false,
      isCheckingBalance: false,
      showScanAuth: true,
      showUnlock: false,
      isPaying: false,
      isWaiting: false,
      showSuccess: false,
      payment: {},
      interval: {},
      payment: {},
      receiverAddress: "",
      pin: "",
      pindigits: [],
      activeTab: 0,
      fromHome: false,
      what: "",
      toAddress: "",
      apiURL: process.env.VUE_APP_BACKEND_URL,
    };
  },
  async mounted() {
    const app = this;
    app.device = await Device.getInfo();
    if (window.location.href.search("receive") !== -1) {
      app.activeTab = 1;
      app.fromHome = true;
    }

    const wallet = await app.did.returnWallet();
    if (wallet !== undefined) {
      app.wallet = wallet;
      app.address = app.wallet.master;
    }

    if (
      app.$route.params.address !== undefined &&
      app.$route.params.amount !== undefined
    ) {
      app.amountSidechain = app.$route.params.amount;
      app.toAddress = app.$route.params.address;
    }
    app.hash = localStorage.getItem("hash");
    app.isLogging = false;
    app.isLoading = false;
  },
  methods: {
    checkAmountAndProceed() {
      const app = this;
      app.isCheckingBalance = true;
      setTimeout(async function() {
        app.amount = app.amount.replace(",", ".");
        if (app.amount > 0) {
          app.did.connect(
            process.env.VUE_APP_BLOCKCHAIN,
            "",
            process.env.VUE_APP_NETWORK
          );
          const contract = await app.did.initContract(
            ABI_TOKEN,
            process.env.VUE_APP_TOKEN_CONTRACT
          );
          const balance = await contract.methods
            .balanceOf(app.wallet.master)
            .call();
          app.did.disconnect();
          const fixedBalance = balance / 100;
          if (fixedBalance >= app.amount) {
            // Check e-mail from basement
            let email;
            try {
              const checkEmail = await app.axios.post(
                "https://ficos.org/api/return-email-hash",
                { hash: app.hash }
              );
              if (
                checkEmail.data.error !== undefined &&
                checkEmail.data.email !== undefined
              ) {
                email = checkEmail.data.email;
                console.log("E-mail found in basement:", email);
              }
            } catch (e) {
              console.log("Can't fetch e-mail, proceeding without it.");
            }
            try {
              const created = await app.axios.post(
                app.apiURL + "/payments/create",
                {
                  order_id: app.order,
                  amount: app.amount,
                  hash_user: email,
                }
              );
              app.showPin = true;
              app.isCheckingBalance = false;
              app.payment = created.data;
              app.receiverAddress = app.payment.address;
            } catch (e) {
              app.isCheckingBalance = false;
              alert(
                "Qualcosa non funziona con il sistema di pagamento, si prega di riprovare!"
              );
            }
          } else {
            app.amount = fixedBalance;
            app.isCheckingBalance = false;
            app.$buefy.toast.open({
              duration: 5000,
              message: "Non hai abbastanza fondi!",
              position: "is-bottom",
              type: "is-danger",
            });
          }
        } else {
          app.isCheckingBalance = false;
          app.$buefy.toast.open({
            duration: 5000,
            message: "Inserisci una quantità valida!",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      }, 100);
    },
    addPin(number) {
      const app = this;
      let display = app.pin.toString();
      display = display.concat(number.toString());
      app.pin = display;
    },
    cleanPin() {
      const app = this;
      app.pin = "";
    },
    removePin() {
      const app = this;
      let display = app.pin.toString();
      let max = display.length - 1;
      display = display.substr(0, max);
      app.pindigits = [];
      if (display.length > 0) {
        app.pin = display;
        for (let i = 1; i <= app.pin.length; i++) {
          app.pindigits.push("*");
        }
      } else {
        app.pin = "";
      }
    },
    async unlockAndSend() {
      const app = this;
      console.log(
        "Unlocking and sending " +
          app.amount +
          " to address: " +
          app.receiverAddress
      );
      const mnemonic = await app.did.decryptWallet("", app.pin);
      if (mnemonic !== false) {
        app.isPaying = true;
        try {
          console.log("Connecting to network");
          app.did.connect(
            process.env.VUE_APP_BLOCKCHAIN,
            mnemonic,
            process.env.VUE_APP_NETWORK
          );
          await app.axios.get(app.apiURL + "/cards/check/" + app.wallet.hash);
          const contract = await app.did.initContract(
            ABI_TOKEN,
            process.env.VUE_APP_TOKEN_CONTRACT
          );
          const fixedAmount = parseFloat(app.amount) * 100;
          // const gasPrice = await app.did.web3.eth.getGasPrice();
          const gasPrice = "1000000000";
          console.log("Gas price is: " + gasPrice);
          const tx = await contract.methods
            .transfer(app.receiverAddress, parseInt(fixedAmount.toString()))
            .send({ from: app.wallet.master, gasPrice: gasPrice });
          app.did.disconnect();
          if (tx["transactionHash"] !== undefined) {
            app.isPaying = false;
            app.showSuccess = true;
            app.receiverAddress = "";
            app.pin = "";
            app.pindigits = [];
            app.showScanAuth = true;
            app.showPin = "";
            app.amount = "";
            console.log("Transaction success: " + tx["transactionHash"]);
            app.$buefy.toast.open({
              duration: 5000,
              message: "Transazione effettuata con successo!",
              position: "is-bottom",
              type: "is-success",
            });
          } else {
            app.isPaying = false;
            app.$buefy.toast.open({
              duration: 5000,
              message: "C'è un problema, si prega di riprovare!",
              position: "is-bottom",
              type: "is-danger",
            });
          }
        } catch (e) {
          console.log(e);
          app.isPaying = false;
          app.$buefy.toast.open({
            duration: 5000,
            message: "C'è un problema, si prega di riprovare!",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      } else {
        app.$buefy.toast.open({
          duration: 5000,
          message: "Il pin non è valido!",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
  watch: {
    pin: function() {
      const app = this;
      app.pindigits = [];
      for (let i = 1; i <= app.pin.length; i++) {
        app.pindigits.push("*");
      }
    },
  },
};
</script>
<style>
.amount-input .input {
  text-align: center;
  font-size: 30px !important;
  margin-top: 5px;
  line-height: 30px;
}
</style>
